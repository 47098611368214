import {
  Box, InputAdornment, TextField, Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { CreateNewRateForm, UseForm } from '../../../utils/types';
import ClientRoleSelectInput from '../Select/ClientRoleSelectInput';
import { QUANTIFIERS } from '../../../utils/constants';

const CreateRateForm: React.FC<CreateRateFormProps> = ({
  formValues,
  setFormValues,
  handleInputChange,
  errors,
}) => {
  const { standardRole, rate, description } = formValues;

  const numberInputProps = {
    startAdornment: (
      <InputAdornment position='start'>
        <Typography sx={{ fontWeight: 'bold', color: 'black' }}>$</Typography>
      </InputAdornment>
    ),
    inputProps: { min: 1 },
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container item direction='column'>
          <Box display='flex' sx={{ justifyContent: 'flex-start' }} flexDirection='row'>
            <Typography
              id='modal-modal-description'
              sx={{ mt: 2, ml: 4 }}
              component='span'
              fontWeight='bold'
            >
              Standard Role
            </Typography>
            <TextField
              label='Standard Role'
              required
              value={standardRole}
              error={Boolean(errors?.standardRole)}
              helperText={errors?.standardRole && errors.standardRole}
              onChange={handleInputChange}
              name='standardRole'
              sx={{ width: '290px', minWidth: '200px', ml: 1 }}
            />
          </Box>
          <Box display='flex' sx={{ justifyContent: 'flex-start' }} flexDirection='row'>
            <Typography
              id='modal-modal-description'
              sx={{ mt: 2, ml: 4 }}
              component='span'
              fontWeight='bold'
            >
              Rate
            </Typography>
            <TextField
              label='Rate'
              required
              value={rate}
              error={Boolean(errors?.rate)}
              InputProps={numberInputProps}
              helperText={errors?.rate && errors.rate}
              onChange={(event) => {
                handleInputChange({
                  target: {
                    name: 'rate',
                    value: event.target.value,
                  },
                });
              }}
              name='rate'
              sx={{ width: '120px', minWidth: '100px', ml: 10 }}
            />
            <ClientRoleSelectInput options={QUANTIFIERS} setFormValue={setFormValues} />
          </Box>
          <Box display='flex' sx={{ justifyContent: 'flex-start' }} flexDirection='row'>
            <Typography
              id='modal-modal-description'
              sx={{ mt: 2, ml: 4 }}
              component='span'
              fontWeight='bold'
            >
              Description
            </Typography>
            <TextField
              label='Description(Optional)'
              multiline
              rows={2}
              value={description}
              error={Boolean(errors?.description)}
              helperText={Boolean(errors?.description) && errors?.description}
              onChange={handleInputChange}
              type='text'
              name='description'
              sx={{ width: '290px', minWidth: '200px', ml: 4 }}
            />
          </Box>
        </Grid>
      </Box>
    </div>
  );
};

export default CreateRateForm;

type CreateRateFormProps = {
  handleInputChange: UseForm<CreateNewRateForm>['handleInputChange'];
  setFormValues: UseForm<CreateNewRateForm>['setFormValues'];
  formValues: Partial<CreateNewRateForm>;
  errors?: { [key in keyof CreateNewRateForm]?: string };
};

CreateRateForm.defaultProps = {
  errors: undefined,
};
