import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridEditCellProps,
} from '@mui/x-data-grid';
import React, { useState } from 'react';
import ReportPreviewManager from '../../../../../business_logic/managers/ReportPreviewManager';
import { PtoFormatted } from '../../../../../business_logic/models/reportPreview';
import useReportPreviewTables from '../../../../../hooks/useReportPreviewTables';
import DateHandler from './DateHandler';
import HolidaysDateColumn from '../CustomColumns/HolidayDates';

interface RowHeight {
  [key: string]: number;
}

const customLocaleText = {
  noRowsLabel: 'There are no PTO Details',
};

const reportPreviewManager = ReportPreviewManager.getInstance();

const ReportPreviewPto: React.FC = () => {
  const {
    datagridInitState,
    ptoDetailsColumnVisibility: initVisibility,
    getRowClassName,
  } = useReportPreviewTables();

  const [rows, setRows] = useState(reportPreviewManager.getAllPtos());
  const [rowHeights, setRowHeights] = useState<RowHeight>({});
  const [visibility, setVisibility] = React.useState<GridColumnVisibilityModel>(initVisibility);

  const updateBillingFactor = (row: PtoFormatted): void => {
    const newLoadingFactor = Number(
      ((row.totalBilledDays / row.workingDays) * row.personTime).toFixed(2),
    );
    const position = reportPreviewManager.getPosition(row.id);
    if (position) {
      position.loadingFactorForBilling = newLoadingFactor;
      reportPreviewManager.updatePosition(position);
    }
  };

  const updateRow = (rowId: string, newRow: PtoFormatted): void => {
    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        reportPreviewManager.updatePto(newRow);
        updateBillingFactor(newRow);
        return newRow;
      }
      return row;
    });
    setRows(updatedRows);
  };

  const updateRowHeight = (rowId: number, newHeight: number) => {
    setRowHeights((prevRowHeights) => ({
      ...prevRowHeights,
      [rowId]: newHeight,
    }));
  };

  const getRowHeight = (params: GridEditCellProps) => {
    const id = String(params.id);
    return rowHeights[id] || 100;
  };

  const boldColumn = (params: GridEditCellProps) => <strong>{params.value}</strong>;

  const columns: GridColDef[] = [
    {
      field: 'client',
      headerName: 'Client',
      headerClassName: 'column-header',
      width: 180,
      disableColumnMenu: false,
      hideable: true,
    },
    {
      field: 'projectName',
      headerName: 'Project Name',
      headerClassName: 'column-header',
      width: 180,
      disableColumnMenu: false,
      hideable: false,
    },
    {
      field: 'roleStatus',
      headerName: 'Role Status',
      headerClassName: 'column-header',
      width: 120,
      disableColumnMenu: true,
      cellClassName: (params: GridEditCellProps) =>
        (params.value === '+1 visible' ? 'plus-one-cell' : ''),
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'column-header',
      width: 180,
      disableColumnMenu: false,
      hideable: true,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      headerClassName: 'column-header',
      width: 180,
      disableColumnMenu: false,
      hideable: true,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      headerClassName: 'column-header',
      width: 180,
      disableColumnMenu: false,
      hideable: true,
    },
    {
      field: 'workingDays',
      headerName: 'Working Days',
      headerClassName: 'column-header',
      width: 120,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params) => boldColumn(params),
    },
    {
      field: 'ptoPlusUto',
      headerName: 'PTO+UTO',
      headerClassName: 'column-header',
      width: 100,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params) => boldColumn(params),
    },
    {
      field: 'ptoPlusUtoDates',
      headerName: 'From To',
      headerClassName: 'column-header-center',
      width: 380,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridEditCellProps) => (
        <DateHandler
          params={params}
          updateRow={(newRow: PtoFormatted): void => {
            const rowId = params.row.id;
            updateRow(rowId, newRow);
          }}
          updateRowHeight={(newHeight: number) => updateRowHeight(params.row.id, newHeight)}
        />
      ),
    },
    {
      field: 'ptoOther',
      headerName: 'PTO (Other)',
      headerClassName: 'column-header',
      width: 120,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params) => boldColumn(params),
    },
    {
      field: 'ptoOtherDates',
      headerName: 'From To',
      headerClassName: 'column-header-center',
      width: 380,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridEditCellProps) => (
        <DateHandler
          params={params}
          updateRow={(newRow: PtoFormatted): void => {
            const rowId = params.row.id;
            updateRow(rowId, newRow);
          }}
          updateRowHeight={(newHeight: number) => updateRowHeight(params.row.id, newHeight)}
        />
      ),
    },
    {
      field: 'holidays',
      headerName: 'Holidays',
      headerClassName: 'column-header',
      width: 90,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params) => boldColumn(params),
    },
    {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'column-header',
      width: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridEditCellProps) => (
        <HolidaysDateColumn
          params={params}
          updateRowHeight={(newHeight: number) => updateRowHeight(params.row.id, newHeight)}
        />
      ),
    },
    {
      field: 'personTime',
      headerName: 'Person Time',
      headerClassName: 'column-header',
      width: 110,
      disableColumnMenu: true,
      align: 'center',
    },
    {
      field: 'totalBilledDays',
      headerName: 'Total Billed Days',
      headerClassName: 'column-header',
      width: 140,
      disableColumnMenu: true,
      renderCell: (params) => boldColumn(params),
    },
  ];

  return (
    <DataGrid
      rows={rows}
      getRowClassName={getRowClassName}
      columns={columns}
      columnVisibilityModel={visibility}
      onColumnVisibilityModelChange={(newModel) => setVisibility(newModel)}
      getRowHeight={(params) => getRowHeight(params)}
      initialState={datagridInitState}
      pageSizeOptions={[12, 25, 50]}
      localeText={customLocaleText}
      disableRowSelectionOnClick
      disableColumnFilter
      disableVirtualization
    />
  );
};

export default ReportPreviewPto;
