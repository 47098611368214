import { useParams } from 'react-router';
import { Stack, Typography } from '@mui/material';
import CustomTabs from '../../molecules/CustomTab';
import NavArrow from '../../molecules/NavArrow';
import ReportPreviewPositions from '../../molecules/Tables/ReportPreview/Positions';
import ReportPreviewWarnings from '../../molecules/Tables/ReportPreview/Warnings';
import ReportPreviewPto from '../../molecules/Tables/ReportPreview/PtoDetails';

const pageSubTitleStyle = {
  fontWeight: 400,
  fontSize: '50px',
  color: 'black',
  textAlign: 'center',
  marginBottom: '2rem',
};

const pageTitleStyle = {
  fontWeight: 500,
  fontSize: '35px',
  color: 'primary.main',
  textAlign: 'center',
};

const tabHeaderStyle = {
  fontWeight: 700,
  minWidth: '12rem',
  color: 'text.primary',
};

const formatDateReport = (receivedDate: string) => {
  const date = new Date(receivedDate);
  const monthReport = date.toLocaleDateString('en-US', { month: 'short' });
  const yearReport = date.getFullYear();
  return `${monthReport} ${yearReport}`;
};

const tabs = [
  {
    id: 'positions',
    label: 'MAIN REPORT',
    content: <ReportPreviewPositions />,
  },
  {
    id: 'ptos',
    label: 'PTO DETAILS',
    content: <ReportPreviewPto />,
  },
  {
    id: 'warnings',
    label: 'WARNINGS',
    content: <ReportPreviewWarnings />,
  },
];

const ReportPreviewPage = () => {
  const { date } = useParams();
  const reportDate = date ? formatDateReport(date) : "Couldn't get the report date";

  return (
    <Stack direction='column'>
      <Stack direction='row' justifyContent='space-between'>
        <NavArrow path='/manualReport' message='Back to Manual Report' forward={false} />
        <NavArrow path='' message='Go to Compensation' forward />
      </Stack>
      <Typography variant='h2' sx={pageTitleStyle}>
        Report Preview
      </Typography>
      <Typography variant='h4' sx={pageSubTitleStyle}>
        {reportDate}
      </Typography>
      <CustomTabs tabs={tabs} tabHeaderStyle={tabHeaderStyle} />
    </Stack>
  );
};
export default ReportPreviewPage;
