/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ListContextProvider, useList, useNotify } from 'react-admin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Alert, Button, Stack, Typography,
} from '@mui/material';
import * as yup from 'yup';
import useForm from '../../../../hooks/useForm';
import CustomModal from '../../CustomModal';
import { CreateNewRateForm, YupSchemaObject } from '../../../../utils/types';
import { RoleRateEntry, RoleRateItem } from '../../../../business_logic/models/roleRateEntry';
import useRolesData from '../../../../hooks/useRolesData';
import ListRates from './ListRates';
import RoleRateEntryParser from '../../../../utils/parsers/roleRateEntryParser';
import SearchInput from '../../SearchInput';
import CreateRateForm from '../../Forms/CreateRateForm';
import { QUANTIFIERS } from '../../../../utils/constants';

const RatesManagerTable: React.FC = () => {
  const [rolesData, setRolesData] = useState<RoleRateItem[]>([]);
  const [rolesDataCopy, setRolesDataCopy] = useState<RoleRateItem[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isCreatingNewRole, setIsCreatingNewRole] = useState(false);
  const [openCreateRoleRateModal, setOpenCreateRoleRateModal] = useState(false);

  const notify = useNotify();
  const { getRolesData, addRoleRate } = useRolesData();
  const listContext = useList({ data: rolesData, sort: { field: 'standardRole', order: 'ASC' } });
  const allowedDecimalPlaces = 2;

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedRoles = await getRolesData({}, { min_max: true });
        const newData: RoleRateItem[] = fetchedRoles.map((roleRate: RoleRateEntry) =>
          RoleRateEntryParser.parseToRoleRateCardItem(roleRate));
        setRolesData(newData);
        setRolesDataCopy(newData);
        setIsLoading(false);
      } catch (error) {
        setHasError(true);
        setIsLoading(false);
      }
    }
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    const filterRoleByName = (searchText: string, role: RoleRateItem) =>
      role.standardRole.toLowerCase().includes(searchText.toLowerCase());
    const searchResult = rolesDataCopy.filter((role) => filterRoleByName(searchInput, role));

    setRolesData(searchResult);
  }, [searchInput]);

  const defaultCreateRoleFormValues: CreateNewRateForm = {
    standardRole: '',
    rate: '',
    typeOfRate: QUANTIFIERS[0],
    description: '',
  };

  const validationSchema: YupSchemaObject<CreateNewRateForm> = {
    standardRole: yup
      .string()
      .min(2, 'At least 2 characters')
      .max(150, 'Max 150 characters')
      .matches(/^[a-zA-Z0-9\s.]*$/, 'Only letters, numbers, spaces, and dots are allowed')
      .required('Field is required')
      .test('first-two-chars-must-be-letters', 'The first 2 characters must be letters', (value) =>
        /^[a-zA-Z]{2}/.test(value))
      .test(
        'duplicates',
        'Standard role already exists',
        (value) =>
          !rolesData.some(
            (role) => role.standardRole.toLocaleLowerCase() === value.trim().toLocaleLowerCase(),
          ),
      )
      .test('no-two-spaces', 'Two continuous spaces are not allowed', (value) => {
        const regex = /\s{2,}/;
        return !regex.test(value);
      })
      .test('no-two-dots', 'Two continuous dots are not allowed', (value) => {
        const regex = /\.{2,}/;
        return !regex.test(value);
      }),
    rate: yup
      .number()
      .typeError('Field must be a number')
      .min(0, 'Only positive numbers')
      .max(99999, 'Max value is 99999')
      .required('Field is required')
      .test(
        'decimal-places',
        `Rate has more than ${allowedDecimalPlaces} decimal places`,
        (value) => {
          const [, decimalPart] = String(value).split('.');
          return !(decimalPart && decimalPart.length > allowedDecimalPlaces);
        },
      ),
    typeOfRate: yup.string().required(),
    description: yup.string().optional(),
  };

  const updateRolesRateData = (newRole: RoleRateItem) => {
    setRolesData((roleDataList) => [...roleDataList, newRole]);
    setRolesDataCopy((roleDataList) => [...roleDataList, newRole]);
  };

  const {
    formValues: createRoleRateFormValues,
    handleInputChange: handleCreateRoleRateInputChanges,
    setFormValues: setCreateRoleRateFormValues,
    reset: resetCreateRoleRateForm,
    errors: createRoleRateFormErrors,
    setErrors: setCreateRoleRateFormErrors,
  } = useForm(defaultCreateRoleFormValues, validationSchema);

  const createRoleRateModalProps = {
    title: 'Create a standard role and its rate',
    isEditModal: true,
  };

  const handleCreateRoleRate = async () => {
    setIsCreatingNewRole(true);

    try {
      const newRole = await addRoleRate(createRoleRateFormValues);
      updateRolesRateData({
        id: newRole.id,
        standardRole: newRole.standard_role,
        rate: newRole.rate,
        typeOfRate: newRole.type_of_rate,
        maxRate: newRole.rate,
        minRate: newRole.rate,
      });
      resetCreateRoleRateForm();
      setOpenCreateRoleRateModal(false);
      notify('Rack rate successfully created', { type: 'success' });
    } catch (error) {
      notify('Error on creating rack rate', { type: 'error' });
    }
  };

  const handleCreateRoleRateClick = () => {
    resetCreateRoleRateForm();
    setIsCreatingNewRole(false);
    setCreateRoleRateFormErrors({});
    setOpenCreateRoleRateModal(true);
  };

  const isDisableCreateRoleRateForm = () => {
    let formHasErrors = false;

    if (createRoleRateFormErrors) {
      formHasErrors = Object.values(createRoleRateFormErrors).length > 0;
    }

    const formHasEmptyValues = Boolean(
      !createRoleRateFormValues.standardRole
        || !createRoleRateFormValues.rate
        || !createRoleRateFormValues.typeOfRate,
    );

    return formHasErrors || formHasEmptyValues || isCreatingNewRole;
  };

  if (isLoading) {
    return <CircularProgress style={{ margin: '0 auto', padding: 0 }} color='inherit' size={24} />;
  }
  if (hasError) {
    return <Typography>Error get data from API</Typography>;
  }

  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <SearchInput
          setSearchQuery={setSearchInput}
          searchQuery={searchInput}
          placeholder='Search by role'
        />
        <Button
          variant='contained'
          onClick={() => handleCreateRoleRateClick()}
          sx={{
            margin: '8px 0 0 40px',
            height: '50px',
            minWidth: '150px',
          }}
          startIcon={<AddCircleOutlineIcon />}
        >
          CREATE RATE
        </Button>
      </Stack>

      <CustomModal
        {...createRoleRateModalProps}
        open={openCreateRoleRateModal}
        handleClose={() => setOpenCreateRoleRateModal(false)}
        handleConfirm={() => handleCreateRoleRate()}
        handleCancel={() => setOpenCreateRoleRateModal(false)}
        disabledConfirmButton={isDisableCreateRoleRateForm()}
        variant='outlined'
        confirmButtonColor='primary'
        confirmButtonText='Create'
        isLoading={isCreatingNewRole}
        width='425px'
        confirmButtonIcon={<AddCircleOutlineIcon />}
      >
        <CreateRateForm
          formValues={createRoleRateFormValues}
          setFormValues={setCreateRoleRateFormValues}
          handleInputChange={handleCreateRoleRateInputChanges}
          errors={createRoleRateFormErrors}
        />
      </CustomModal>

      {rolesData.length ? (
        <ListContextProvider value={{ ...listContext }}>
          <ListRates setData={setRolesData} />
        </ListContextProvider>
      ) : (
        <Alert sx={{ height: '2.5rem', alignSelf: 'flex-center' }} severity='info'>
          No results found
        </Alert>
      )}
    </>
  );
};

export default RatesManagerTable;
