import React, { useState } from 'react';

import { useList, ListContextProvider, Pagination } from 'react-admin';
import ListInvoice from './ListInvoice';
import { Invoice } from '../../../../business_logic/models/invoice';

type PreviewListProps = {
  month: number;
  year: number;
};

const PreviewList: React.FC<PreviewListProps> = () => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const listContext = useList<Invoice>({ data: [] });

  if (listContext.isLoading) {
    return <div>Loading..</div>;
  }

  return (
    <ListContextProvider
      value={{
        ...listContext,
        total: listContext.total || 0,
        setPage,
        page,
        perPage,
        setPerPage,
      }}
    >
      <ListInvoice />
      <Pagination />
    </ListContextProvider>
  );
};

export default PreviewList;
