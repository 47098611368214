import {
  RoleRateFilter,
  RoleRateHistoryFilter,
  RoleRateStatistics,
} from '../business_logic/models/roleRateEntry';
import useRoleRateHistoryRepository from './useRoleRateHistoryRepository';

import Config from '../utils/config';
import { ClientRoleRateReq, CreateNewRateForm, EditRateForm } from '../utils/types';
import useRoleRatesRepository from './useRoleRatesRepository';
import { FileDB } from '../business_logic/models/fileDb';

const ROLE_RATE_BASE_URL = 'role-rate';

const useRolesData = () => {
  const {
    addRoleRate: addNewRoleRate,
    getRoles,
    updateRole,
  } = useRoleRatesRepository({ baseURL: Config.backendUrl });
  const { getRoleRatesHistory } = useRoleRateHistoryRepository({ baseURL: Config.backendUrl });
  const getRolesData = async (
    filters: RoleRateFilter = {},
    withStatistics: RoleRateStatistics = {},
  ) => getRoles(`${ROLE_RATE_BASE_URL}/filter`, filters, withStatistics);

  const getRoleHistoryData = async (filter: RoleRateHistoryFilter) =>
    getRoleRatesHistory(`${ROLE_RATE_BASE_URL}-history/filter`, filter);

  const addRoleRate = async ({
    standardRole,
    rate: newRate,
    typeOfRate,
    description: note,
  }: CreateNewRateForm) => {
    const newRoleRate = {
      roleRate: {
        standard_role: standardRole,
        rate: parseFloat(newRate),
        type_of_rate: typeOfRate,
      },
      description: note,
    };
    return addNewRoleRate(newRoleRate, ROLE_RATE_BASE_URL);
  };

  const updateRoleRate = async ({
    id,
    description,
    files,
    rate = 0,
  }: EditRateForm & { files?: FileDB | FileDB[] }) => {
    const roleData = {
      roleRate: {
        id,
        rate: rate > 0 ? rate : 0,
      },
      files,
      description,
    };

    return updateRole(ROLE_RATE_BASE_URL, roleData);
  };

  const updateClientRoleRate = async (updateClientRoleRateReq: ClientRoleRateReq) =>
    updateRole(ROLE_RATE_BASE_URL, updateClientRoleRateReq);

  return {
    getRolesData,
    addRoleRate,
    getRoleHistoryData,
    updateRoleRate,
    updateClientRoleRate,
  };
};
export default useRolesData;
