import { GridColDef, GridColumnVisibilityModel, GridEditCellProps } from '@mui/x-data-grid';
import ReportPreviewManager from '../business_logic/managers/ReportPreviewManager';
import DateMethods from '../utils/dateMethods';

const useReportPreviewTables = () => {
  const reportPreviewManager = ReportPreviewManager.getInstance();

  const getColumnWidth = (columns: GridColDef[], columnVisibility: GridColumnVisibilityModel) => {
    let totalWidth = 0;
    columns.forEach((column) => {
      if (columnVisibility[column.field]) {
        totalWidth += column.width ? column.width : 0;
      }
    });
    return totalWidth;
  };

  const getRowHeight = (currentDates: string[], otherDates: string[]) => {
    const currentDatesHeight = currentDates.reduce((accumulator: number) => accumulator + 40, 55);
    const otherDatesHeight = otherDates.reduce((accumulator: number) => accumulator + 40, 55);
    return Math.max(currentDatesHeight, otherDatesHeight);
  };

  const getRowClassName = (params: GridEditCellProps) => {
    const { row } = params;
    const { startDate, endDate } = row;

    const [firstDayOfMonth, lastDayOfMonth] = DateMethods.getFirstAndLastDayOfMonth(
      reportPreviewManager.getDate(),
    );
    const formatFirstDayOfMonth = new Date(DateMethods.addZeroTime(firstDayOfMonth));
    const formatLastDayOfMonth = new Date(DateMethods.addZeroTime(lastDayOfMonth));

    const formatEndDate = endDate ? new Date(DateMethods.addZeroTime(endDate)) : null;
    const formatStartDate = startDate ? new Date(DateMethods.addZeroTime(startDate)) : null;

    if (
      formatEndDate
      && (formatEndDate < formatFirstDayOfMonth || formatEndDate <= formatLastDayOfMonth)
    ) {
      return 'highlight-red-row';
    }

    if (formatStartDate && formatStartDate >= formatFirstDayOfMonth) {
      return 'highlight-green-row';
    }

    return '';
  };

  const positionsColumnVisibility = {
    client: true,
    invoice: false,
    projectName: true,
    roleStatus: true,
    name: true,
    role: true,
    level: true,
    quantifier: true,
    personTime: true,
    loadingFactorForBilling: true,
    startDate: true,
    endDate: true,
  };

  const ptoDetailsColumnVisibility = {
    name: true,
    roleStatus: true,
    workingDays: true,
    ptoPlusUto: true,
    ptoPlusUtoDates: true,
    ptoOther: true,
    ptoOtherDates: true,
    holidays: true,
    date: true,
    personTime: true,
    totalBilledDays: true,
  };

  const warningsColumnVisibility = {
    name: true,
    client: true,
    projectName: true,
    description: true,
  };

  const datagridInitState = {
    pagination: {
      paginationModel: {
        pageSize: 12,
      },
    },
  };

  return {
    getColumnWidth,
    getRowHeight,
    getRowClassName,
    positionsColumnVisibility,
    ptoDetailsColumnVisibility,
    warningsColumnVisibility,
    datagridInitState,
  };
};

export default useReportPreviewTables;
