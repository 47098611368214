import { RoleRateEntry, RoleRateItem } from '../../business_logic/models/roleRateEntry';

const RoleRateEntryParser = {
  parseToRoleRateCardItem(roleEntry: RoleRateEntry): RoleRateItem {
    return {
      id: roleEntry.id,
      standardRole: roleEntry.standard_role,
      rate: roleEntry.rate,
      typeOfRate: roleEntry.type_of_rate,
      maxRate: roleEntry.max_rate,
      minRate: roleEntry.min_rate,
    };
  },
};

export default RoleRateEntryParser;
